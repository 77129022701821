import React from 'react'
import './rupeshbanks.css'
import img1 from '../photos/aeps.png'
import img2 from '../photos/aadhar-pay.png'
import img3 from '../photos/Cash-Deposit.png'
import img4 from '../photos/money-transfer-1.png'
import img5 from '../photos/demat-account.png'
import img6 from '../photos/Birth-Certificate.png'
import img7 from '../photos/CSP.png'
import img8 from '../photos/money-transfer-1.png'
import img9 from '../photos/credit-card-bill-payment.png'
import img10 from '../photos/LoanPayment.png'
import img11 from '../photos/MICRO-ATM.png'
import { Card } from 'antd'

function Rupeshseva_bank_service() {
  const Data = [
    {
      Img: img8,
      Name: 'मनी ट्रांसफर'
    },
    {
      Img: img1,
      Name: 'एईपीएस सेवा'
    },
    {
      Img: img2,
      Name: 'आधार पे'
    },
    {
      Img: img3,
      Name: 'नकद जमा'
    },
    {
      Img: img4,
      Name: 'नया बैंक खाता'
    },
    {
      Img: img5,
      Name: 'डीमैट खाता'
    },
    {
      Img: img6,
      Name: 'ऋण आवेदन'
    },
    {
      Img: img7,
      Name: 'बैंकिंग बीमा PMSBY,PMJJBY'
    },
    
    {
      Img: img9,
      Name: 'क्रेडिट कार्ड आवेदन करें'
    },
    {
      Img: img10,
      Name: 'कर्ज का भुगतान'
    },
    {
      Img: img11,
      Name: 'माइक्रो एटीएम'
    },
    {
      Img: img7,
      Name: 'CRGB कियोस्क बैंकिंग'
    },
  
  ]
  
  return (
    <>
      <div className='Bankingservices_maindiv' id='bank'>
          <h1 className='Bankingservices_heading'>बैंकिंग सेवाएं</h1>
      </div>
      <div className='divs4_block'>
        {Data.map((item, index) => (
          <Card key={index} className='Img4--name_div'>
            <img src={item.Img} className='Img4-type_Img'></img>
            <p className='Img4-name_h4'>{item.Name}</p>
          </Card>
        ))}
      </div>

 
    </>
  )
}

export default Rupeshseva_bank_service

