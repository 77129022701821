import React, { useState } from 'react';
import { Image, Button, Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import './Rupeshseva_services.css';
import img1 from '../photos/bank7.png';
import img2 from '../photos/Recharge.png';
import img3 from '../photos/govt2.jpeg';
import img4 from '../photos/tax3.png';
import img5 from '../photos/travel.png';
import Rupeshsev_recharge from "./Rupeshsev_recharge";
import Rupeshsev_bank_service from "./Rupeshsev_bank_service";
import Rupeshseva_govtservice from "./Rupeshseva_govtservice";
import Rupeshseva_taxservice from "./Rupeshseva_taxservice";
import Rupestseva_travelservice from "./Rupestseva_travelservice";
function Rupeshseva_services() {
  const navigate = useNavigate()
  const data = [
    {
      "title": "बैंकिंग सेवाएं",
      "services": [
        { "img": img1 },
        { "description": 'हम बचत खाते, चालू खाते, ऋण, और क्रेडिट कार्ड जैसी बैंकिंग सेवाएं प्रदान करते हैं, जो ग्राहकों की जरूरतों और वित्तीय लक्ष्यों को पूरा करने में मदद करती हैं।' },
        { "name": 'बैंकिंग सेवाएं' }
      ],
      path: '/bank'
    },
    {
      "title": "रिचार्ज सेवाएँ",
      "services": [
        { "img": img2 },
        {
          "description": "हम मोबाइल रिचार्ज और बिजली बिल भुगतान जैसी सेवाएं प्रदान करते हैं। हमारी सेवाएं ग्राहकों के लिए बिल भुगतान और रिचार्ज को आसान बनाने के लिए डिज़ाइन की गई हैं।"
        },
        { "name": "रिचार्ज और बिल भुगतान सेवाएं" }
      ],
      path: '/recharge'
    },
    {
      "title": "शासकीय सेवाएं",
      "services": [
        { "img": img3 },
        {
          "description": "हम पासपोर्ट, वीज़ा, और ड्राइविंग लाइसेंस जैसी सरकारी सेवाएं प्रदान करते हैं, जो ग्राहकों के लिए इन सेवाओं तक पहुंच को आसान और सुविधाजनक बनाती हैं।"
        },
        { "name": "शासकीय सेवाएं" }
      ],
      path: '/govt'
    },
    // {
    //   "title": "Tax",
    //   "services": [
    //     { "img": img4 },
    //     {
    //       "description": "हम कंपनी पंजीकरण और आयकर फाइलिंग सेवाएं प्रदान करते हैं, जिससे ये प्रक्रियाएं  सुविधाजनक हो जाती हैं।"
    //     },
    //     { "name": "पंजीकरण और कर दाखिल करने की सेवाएं" }
    //   ],
    //   path: '/tax'
    // },
    {
      "title": "यात्रा सेवाएं",
      "services": [
        { "img": img5 },
        {
          "description": "हम उड़ान बुकिंग, होटल बुकिंग, और कार किराए पर लेने जैसी यात्रा सेवाएं प्रदान करते हैं, जो ग्राहकों के लिए यात्रा की योजना बनाना और बुक करना आसान बनाती हैं।"
        },
        { "name": "यात्रा सेवाएँ" }
      ],
      path: '/travel'
    }
  ];

  const [title, setTitle] = useState(data);

  const handleFilter = (e) => {
    navigate(e)
  }
  return (
    <div id='services'>
    <div className='Services_CSC_Main_divs'>
      {/* <h1 className='rupeshseva_service_heading'>SERVICES</h1> */}
      {/* <div className='rupeshseva_service_div1'>
        {title.map(titleService => (
          <Card key={titleService.title} className='rupeshseva_service_card1'>
            <Image className='rupeshseva_service_img' width={100} src={titleService.services[0].img} alt={titleService.title} />
            <h2 className='rupeshseva_service_heading2'>{titleService.title}</h2>
            <p className='rupeshseva_service_description'>{titleService.services[1].description}</p><br></br>
            <Button className='rupeshseva_service_button' onClick={() => handleFilter(titleService.path)}>आगे देखे</Button>
          </Card>
        ))}
      </div> */}
<Rupeshsev_bank_service/>
<Rupeshseva_govtservice/>
<Rupeshsev_recharge/>
<Rupestseva_travelservice/>
    </div>
    </div>
  );
}

export default Rupeshseva_services;