import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor:'#1B4F72',
    color: 'gold',
    fontSize: 17,
    padding: '8px', // Adjust the padding for table head cells
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '8px', // Adjust the padding for table head cells
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 2,
  },
}));

function createData(name, calories) {
  return { name, calories };
}

const rows = [
  createData(1.,'ट्रेनिंग सर्टीफिकेट'),
  createData(2., 'बैंक पासबुक फोटोकापी'),
  createData(3., 'नियोजक के संबंध में शासन द्वारा जारी निर्धारित प्रपत्र में स्वघोषणा प्रमाण पत्र'),
  createData(4., 'श्रमिक का आधार कार्ड'),
  

];

export default function CustomizedTables() {
  return (
    <TableContainer component={Paper} sx={{ overflow: 'auto' }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>क्र.</StyledTableCell>
            <StyledTableCell align="bottom">दस्तावेज के नाम</StyledTableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="bottom">{row.calories}</StyledTableCell>
              
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}