import React from 'react'
import './rupeshtravel.css'
import img1 from '../photos/IRCTC.png'
import img2 from '../photos/hotel-booking.png'
import img3 from '../photos/flight-booking.png'
import img4 from '../photos/bus-booking.png'

import { Card } from 'antd'

function Services() {
  const Data = [
    {
      Img: img4,
      Name: 'बस बुकिंग'
    },
    {
      Img:  img1,
      Name: 'ट्रेन बुकिंग '
    },
    {
      Img:  img2,
      Name: 'होटल बुकिंग'
    },
    {
      Img: img3,
      Name: 'फ़्लाइट बुकिंग'
    }
   
  ]
  return (
   
        <>
      <div className='yatra_services_maindiv' id='travel'>
          <h1 className='yatra_services_heading'>यात्रा सेवाएं</h1>
      </div>
      <div className='yatra_block'>
        {Data.map((item, index) => (
          <Card key={index} className='yatra_name_div'>
            <img src={item.Img} className='yatra_Img'></img>
            <p className='yatra_name'>{item.Name}</p>
          </Card>
        ))}
      </div>
    </> 
  )
}

export default Services

