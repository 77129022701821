import React from 'react'
import './Rupeshseva_whytouse.css'

function Rupeshseva_whytouse() {
  return (
    <div className='rupeshseva_whytouse_main_divwhy'>

       <div >
       <h1 className='rupeshseva_why_heading'>हम सर्वश्रेष्ठ डिजिटल सेवा केंद्र क्यों हैं?</h1>

    <p className='rupeshseva_whytouse_para'>
    रूपेश ग्राहक सेवा केंद्र भारत में एक सरकारी केंद्र या डिजिटल सेवा केंद्र है जो अपने उपयोगकर्ताओं को एक ही छत के नीचे 
    ऑनलाइन सेवाओं की एक विस्तृत श्रृंखला प्रदान करता है।</p>
    
    
    <p className='rupeshseva_whytouse_para'>
    डिजिटल सेवा केंद्र के रूप में भी जाना जाने वाला यह एक सरकारी पहल है जिसका उद्देश्य एक स्थान 
    पर सेवाएं प्रदान करके अपने उपयोगकर्ताओं को सुविधा और आसान पहुंच प्रदान करना है।
    </p>
    
    
    <p className='rupeshseva_whytouse_para'>उपयोगकर्ता विभिन्न कार्यालयों में जाने या अपनी बारी का इंतजार करने के लिए लंबी कतारों
         में खड़े हुए बिना सभी गतिविधियों को ऑनलाइन करके बहुत समय और पैसा बचा सकते हैं।</p>

 
 
 <p className='rupeshseva_whytouse_para'>हम शहरी और ग्रामीण दोनों क्षेत्रों के निवासियों के जीवन को अधिक बेहतर बनाने के लिए बैंकिंग,
     बिल भुगतान, सरकारी और पंजीकरण सेवाओं सहित कई प्रकार की डिजिटल सेवा सेवाएं प्रदान करते हैं।</p>
     <p className='rupeshseva_whytouse_para'>
     सभी सरकारी सेवाएँ  ग्राहक सेवा केंद्र के अंतर्गत उपलब्ध हैं जिन्हें डिजिटल सेवा केंद्र के रूप में भी जाना जाता है। 
     हम आपको शहरी और ग्रामीण क्षेत्रों के बीच की खाई को पाटने में मदद करते हैं और हम
      सभी आवश्यक सेवाओं के लिए आसानी से और सुविधाजनक रूप से डिजिटल सेवा प्लेटफार्मों तक समान पहुँच प्रदान करते हैं।
     </p>

    </div>

    </div>
  )
}

export default Rupeshseva_whytouse
