import React from 'react'
import './rupeshgovts.css'
import img1 from '../photos/Aadhar-Udyog.png'
import img2 from '../photos/aadhar-updartepng.png'
import img3 from '../photos/Pan-card.png'
import img4 from '../photos/Driving-Licence.png'
import img5 from '../photos/voter-idcard.png'
import img6 from '../photos/Birth-Certificate.png'
import img7 from '../photos/lABOURCARD.png'
import img8 from '../photos/labour-card.png'
import img9 from '../photos/satyamev.png'
import img10 from '../photos/RojgarPangiwan.png'
import img11 from '../photos/caste-certificate.png'
import img12 from '../photos/e-shramcard.png'
import img13 from '../photos/ration-card.png'
import img14 from '../photos/Bhuiyan.png'
import { Card } from 'antd'
function Rupeshseva_govtservice() {
  const Data = [
    {
      Img: img3,
      Name: 'पैन कार्ड '
    },
    {
      Img: img4,
      Name: 'ड्राइविंग लाइसेंस'
    },
    {
      Img: img5,
      Name: 'वोटर आई कार्ड'
    },
    {
      Img: img6,
      Name: 'जन्म प्रमाणपत्र'
    },
    {
      Img: img10,
      Name: 'रोजगार पंजीयन'
    },
    {
      Img: img7,
      Name: 'निवास प्रमाण'
    },
   
    {
      Img: img9,
      Name: 'आय प्रमाण पत्र'
    },
   
    {
      Img: img11,
      Name: 'जाति प्रमाण पत्र'
    },
    {
      Img: img8,
      Name: 'मजदुर कार्ड'
    },
    {
      Img: img12,
      Name: 'ई-श्रम कार्ड'
    },
    {
      Img: img13,
      Name: 'राशन पत्रिका'
    },
    {
      Img: img14,
      Name: 'नक्शा खसरा और बी1'
    },
   
  ]
  return (
    <>
      <div className='govt_services_maindiv' id='govt'>
          <h1 className='govt_services_heading'>शासकीय सेवाएं</h1>
      </div>
      <div className='govt_block'>
        {Data.map((item, index) => (
          <Card key={index} className='govt_name_div'>
            <img src={item.Img} className='govt_Img'></img>
            <p className='govt_name'>{item.Name}</p>
          </Card>
        ))} 
      </div>
    </>
  )
}

export default Rupeshseva_govtservice

