import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import logo from '../photos/Rupesh_CSC_Logo-removebg-preview.png';
import Sidebar from "../Nav/Sidebar";
import Backdrop from "./Backdrop";
import BurgerIcon from "./BurgerIcon";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
const TopNavbar = () => {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigateHome = () => {
    // Optionally navigate to '/' when Home is clicked
    navigate('/');
  };

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className={y > 100 ? "fixed" : ""}>
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="home" smooth={true}>
            <img src={logo} alt="logo" width={150} />
          </Link>
          <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)} aria-label="Toggle Sidebar">
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="home" spy={true} smooth={true} offset={-80} >
                Home
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="about" spy={true} smooth={true} offset={-100} >
                About
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="services" spy={true} smooth={true} offset={-80} >
                Services
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link  style={{ padding: "10px 15px" }} to="contact" spy={true} smooth={true} activeClass="active" offset={-160} >
                Contact
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="yojna" spy={true} smooth={true} offset={-160} >
                <Button style={{ color: 'gold', backgroundColor: "#1B4F72", borderRadius: "20px" }}> योजनाएं</Button>
              </Link>
            </li>
          </UlWrapper>
        </NavInner>
      </Wrapper>
    </>
  );
}

export default TopNavbar;

const Wrapper = styled.nav`
  width: 100%;
  z-index: 999;
  background-color: white; /* Example background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Example shadow for visual depth */
  transition: height 0.3s ease; /* Smooth transition for height change */
  
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
  }
`;

const NavInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px; /* Initial height */
  padding: 0 20px;
`;

const BurderWrapper = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;

const UlWrapper = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  @media (max-width: 760px) {
    display: none;
  }
`;
