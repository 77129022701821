import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import './Rupeshseva_header.css'
import { FaPhoneAlt, FaEnvelope, FaHome, FaServicestack, FaQuestionCircle, FaInfoCircle } from 'react-icons/fa';
import { Button, Carousel } from 'antd';
import { FaFileContract } from 'react-icons/fa6';
import img1 from '../photos/hero-img.png'
import logo from '../photos/Rupesh_CSC_Logo-removebg-preview.png'
import { Row, Col } from 'antd'
import Column from 'antd/es/table/Column';
import { FaInstagramSquare, FaFacebook,FaLinkedin } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import TopNavbar from '../Nav/TopNavbar';
function Rupeshseva_header() {
  const navigate = useNavigate()

  const Yojnaye = () => {
    navigate('/yojana')

  }
  return (
    <div>
      <Row className='main_row'>

        <Col className='main_head_col'>
           <a href="tel:${9617787279}" style={{color:"white"}}><FaPhoneAlt />&nbsp;  9617787279</a>&nbsp;, &nbsp;    
          <a href="tel:${9109799434}" style={{color:"white"}}>9109799434</a>

        </Col>
        <Col className='main_head_col' >
          <span id='contact_no_id'>
            <FaInstagramSquare />&nbsp;&nbsp;
            <FaFacebook />&nbsp;&nbsp;
            <FaLinkedin />&nbsp;&nbsp;
            <IoLogoYoutube />&nbsp;&nbsp;
          </span>
        </Col>
      </Row>


<TopNavbar/>


















      {/* <div className='rupesh_hero_heading_div3_main'>
        <div className='rupesh_hero_heading_div3'>
          <div className='logoof_header' >
            <img src={logo} className='shivhero_logo_img' />
          </div>
          <div className='rupesh_hero_contener_div4'>
            <Link to='/' > <span className='rupeshseva_hero_content'>
              <FaHome />&nbsp;Home </span>&nbsp;&nbsp;&nbsp;
            </Link>
            <span className='rupeshseva_hero_content'  >
              <FaInfoCircle />&nbsp;About Us</span>&nbsp;&nbsp;&nbsp;
            <span className='rupeshseva_hero_content' >
              <FaServicestack />&nbsp;Services</span>&nbsp;&nbsp;&nbsp;
            <Link to='/contact' ><span className='rupeshseva_hero_content' >
              <FaFileContract />&nbsp;Contact</span>
            </Link>
            <Button onClick={Yojnaye} className='rupeshseva_button_enquiry'>योजनाएं</Button>


          </div>
        </div>
      </div> */}

      
      {/* <Row className='all_content_row'>
        <Col>
          <div className='logoof_header' >
            <img src={logo} className='shivhero_logo_img' />
          </div>
        </Col>
        <Col span={5}></Col>
        <Col className='contentitems_col' >
          <Link to='/' > <div className='rupeshseva_hero_content' >
            <FaHome />&nbsp;Home </div>&nbsp;&nbsp;&nbsp;
          </Link>
        </Col>

        <Col className='contentitems_col'>
          <span className='rupeshseva_hero_content'  >
            <FaInfoCircle />&nbsp;About Us</span>&nbsp;&nbsp;&nbsp;
        </Col>
        <Col className='contentitems_col'>
          <span className='rupeshseva_hero_content' >
            <FaServicestack />&nbsp;Services</span>&nbsp;&nbsp;&nbsp;
        </Col>
        <Col className='contentitems_col'>
          <Link to='/contact' ><span className='rupeshseva_hero_content' >
            <FaFileContract />&nbsp;Contact</span>
          </Link>
        </Col>
        <Col className='contentitems_col'>
          <Button onClick={Yojnaye} className='rupeshseva_button_enquiry'>योजनाएं</Button>

        </Col>
      </Row> */}

    </div>


  )
}

export default Rupeshseva_header