import React from 'react'
import Meenamatamahtari from '../YojnaPages/Meenamatamahtari'
import Nanihalchatrawas from '../YojnaPages//Nanihalchatrawas'
import Nonishasaktikaran from '../YojnaPages//Nonishasaktikaran'
import Cyclesehwaka from '../YojnaPages//Cyclesehwaka'
import Silawayimachine from '../YojnaPages//Silawayimachine'
import Shramikauzar from '../YojnaPages//Shramikauzar'
import Shramiksiwan from '../YojnaPages//Shramiksiwan'
import './Rupeshseva_yojnaye.css'
import { Card } from 'antd'

function Rupeshseva_yojnaye() {
  return (
    <div id='yojna'>
      <br></br>
      <h2 className='heading'><u>आवश्यक दस्तावेज</u></h2>
      <div className='DIV1'>
        <Card className='CARD1' title={<span >मिनीमाता महतारी जतन योजना</span>}>
          <Meenamatamahtari />
        </Card>
        <Card className='CARD2' title={<span>मुख्यमंत्री नौनिहाल छात्रवृत्ति योजना</span>}>
          <Nanihalchatrawas />
        </Card>
      </div>
      <div className='DIV1'>
        <Card className='CARD1' title={<span>मुख्यमंत्री श्रमिक औजार सहायता योजना</span>}>
          <Shramikauzar />
        </Card>
        <Card className='CARD2' title={<span>मुख्यमंत्री सायकल सहायता योजना</span>}>
          <Cyclesehwaka />
        </Card>
      </div>
      <div className='DIV1'>
        <Card className='CARD1' title={<span>मुख्यमंत्री सिलाई मशीन सहायता योजना</span>}>
          <Silawayimachine />
        </Card>
        <Card className='CARD2' title={<span>मुख्यमंत्री श्रमिक सियान सहायता योजना </span>}>
          <Shramiksiwan />
        </Card>
      </div>
      <div className='DIV1'>
        <Card className='CARD1' title={<span>मुख्यमंत्री नोनी सशक्तिकरण सहायता योजना</span>} >
          <Nonishasaktikaran />
        </Card>
      </div>

    </div>
  )
}

export default Rupeshseva_yojnaye
