import React from 'react'
import './rupeshrecharge.css'
import img1 from '../photos/Mobile-Recharge.png'
import img2 from '../photos/NewestRecharge.png'
import img3 from '../photos/Electricity-Bill.png'
import img4 from '../photos/Water.png'
import img5 from '../photos/gasbill.png'
import img6 from '../photos/insuranceservice.png'
import img7 from '../photos/landlinebill.png'
import img8 from '../photos/credit-card-bill.png'
import img9 from '../photos/LICbill.png'
import img10 from '../photos/Fastagrecharge.png'
import img11 from '../photos/airtelsim.wep.webp'
import img12 from '../photos/SIMPORT1.png'
import { Card } from 'antd'
 
function Rupeshsev_recharge() {
    const Data = [
        {
            Img: img1,
            Name: 'मोबाइल रिचार्ज'
        },
        {
            Img: img2,
            Name: 'डीटीएच रिचार्ज'
        },
        {
            Img: img3,
            Name: 'बिजली बिल'
        },
        {
            Img: img4,
            Name: 'पानी का बिल '
        },
        {
            Img: img5,
            Name: 'गैस का बिल'
        }, {
            Img:img6,
            Name: 'बीमा सेवा'
        },
        {
            Img: img7,
            Name: 'लैंडलाइन बिल'
        },
        {
            Img: img8,
            Name: 'क्रेडिट कार्ड का बिल'
        }, {
            Img: img9,
            Name: 'लाइसेंस बिल '
        },
        { 
            Img: img10,
            Name: 'फास्टैग रिचार्ज'
        },
        {
            Img: img11,
            Name: 'नया एयरटेल सिम'
        },
        {
            Img: img12,
            Name: 'सिम पोर्ट'
        },

    ]
    return (
                <>
      <div className='recharge_services_maindiv' id='recharge'> 
          <h1 className='recharge_services_heading'>रिचार्ज और बिल भुगतान सेवाएं</h1>
      </div>
      <div className='recharge_block'>
        {Data.map((item, index) => (
          <Card key={index} className='recharge_name_div'>
            <img src={item.Img} className='Img4-type_Img'></img>
            <p className='Img4-name_h4'>{item.Name}</p>
          </Card>
        ))}
      </div>
    </>

    )
}

export default Rupeshsev_recharge