import React from 'react'
import './rupeshtax.css'
import img1 from '../photos/Gst-registration.png'
import img2 from '../photos/gst-return.png'
import img3 from '../photos/gst-correction.png'
import img4 from '../photos/one-person-company.png'
import img5 from '../photos/Add-remove-partner.png'
import img6 from '../photos/change-business-name.png'
import img7 from '../photos/one-person-company.png'
import img8 from '../photos/Section-8-company.png'
import img9 from '../photos/income-tax-return.png'
import img10 from '../photos/fssai-registration.png'
import img11 from '../photos/tds-return.png'
import img12 from '../photos/tan-registration.png'

import { Card } from 'antd'


function Services() {
  const Data = [
    {
      Img:  img1,
      Name: 'जीएसटी पंजीकरण'
    },
    {
      Img: img2,
      Name: 'जीएसटी रिटर्न'
    },
    {
      Img: img3,
      Name: 'जीएसटी सुधार'
    },
    {
      Img: img4,
      Name: 'एक व्यक्ति कंपनी'
    },
    {
      Img:img5,
      Name: 'प्राइवेट लिमिटेड पंजीकरण'
    },
    {
      Img: img6,
      Name: 'व्यवसाय का नाम बदलें'
    },
    {
      Img: img7,
      Name: 'भागीदार जोड़ें/हटाएँ'
    },
    {
      Img:img8,
      Name: 'धारा-8 कंपनी'
    },
    {
      Img: img9,
      Name: 'इनकम टैक्स रिटर्न'
    },
    {
      Img: img10,
      Name: 'एफएसएसएआई पंजीकरण'
    },
    {
      Img: img11,
      Name: 'टीडीएस रिटर्न'
    },
    {
      Img:img12,
      Name: 'टैन पंजीकरण'
    } 
  ]
  return (
    <>
 
    <br></br><br></br><br></br><br></br><br></br><br></br>
      <div className='Main_services_block'>
        <div className='Services_Main_page'>
          <h1 className='h1s2'>पंजीकरण और कर दाखिल करने की सेवाएं</h1>
        </div>
        </div>
        <div className='divs_block'>
          {Data.map((item, index) => (
            <Card key={index} className='Img--name_div'>
              <img src={item.Img} className='Img-type_Img'></img>
              <p className='Img-name_h4'>{item.Name}</p>
            </Card>
          ))}
        </div>
      
  
    </>
  )
}

export default Services

