import React from 'react'
import imagelogo from '../photos/Rupesh_CSC_Logo-removebg-preview.png'
import { Card } from 'antd';
import './rupeshenquiry.css'
import { EnvironmentOutlined, PhoneOutlined, MessageOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import BasicTextFields from './BasicTextFeilds'
function Rupeshseva_Enquiry() {

  return (
    <div id='contact'>
      <h1 className='header_contact'>CONTACT</h1>
      <div className='RupeshGrahk_contactdiv'>

        <div className='firstdivof_Contact'>
          <div>

            <p className='subpara_contact'>यदि आपके कोई प्रश्न हैं या समर्थन की आवश्यकता है
              बेझिझक हमारे संपर्क फ़ॉर्म के माध्यम से हमसे संपर्क करें
              या समर्थन पृष्ठ.
            </p>
            <div >
              <iframe className='googlemap_rupesh'
                title="location-map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2832.9089467921785!2d81.
              62338585145848!3d21.22784409368442!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.
              1!3m3!1m2!1s0x3a28ddc819881b2b%3A0x8a55ea8a3bb3220e!2shari%20om%20kirana%20and%
              20general%20store!5e1!3m2!1sen!2sin!4v1721298724346!5m2!1sen!2sin"
                width="600"
                height="315"

                style={{ border: 0, paddingLeft: '10%' }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
        <div className='seconddivof_Contact'>
          <div className='imported'><BasicTextFields /></div>

        </div>
      </div>

    </div>
  )
}

export default Rupeshseva_Enquiry

