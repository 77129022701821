import "./App.css";
import { BrowserRouter as Router, Route, Routes, BrowserRouter } from "react-router-dom";
import Landing from './Landing page/Landing'
import Rupeshseva_yojnaye from "./YojnaPages/Rupeshseva_yojnaye";
import Rupeshsev_bank_service from "./Services/Rupeshsev_bank_service";
import Rupeshsev_recharge from "./Services/Rupeshsev_recharge";
import Rupeshseva_govtservice from "./Services/Rupeshseva_govtservice";
import Rupeshseva_taxservice from "./Services/Rupeshseva_taxservice";
import Rupestseva_travelservice from "./Services/Rupestseva_travelservice";
import Rupeshseva_Enquiry from "./Contact/Rupeshseva_Enquiry";
import Rupeshseva_header from "./Header/Rupeshseva_header"
import Rupeshseva_footer from "./Footer/Rupeshseva_footer"
function App() {
  return (
    <>
  
    <Router>
     <Rupeshseva_header/>
        <Routes>
          <Route path="/" element={<Landing />} />
      
        </Routes>
       <br/>
      <Rupeshseva_footer/>
    </Router>
  
    </>
  );
}

export default App;
