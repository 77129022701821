import React from 'react'
import './Rupeshseva_footer.css'
import { Link } from 'react-scroll'
import { FaEnvelope, FaLocationArrow, FaPhone, FaVoicemail } from 'react-icons/fa'
import logo from '../photos/Rupesh CSC Logo.png'
import { FloatingWhatsApp } from 'react-floating-whatsapp2';
import { FaPhoneAlt } from 'react-icons/fa';
import { FaInstagramSquare, FaFacebook, FaLinkedin } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";

function Rupeshseva_footer() {
    return (
        <>
            <div className='footer_rupesh'>
                <div className='one_div'>
                    <div className='name'>
                        रूपेश ग्राहक सेवा केंद्र
                    </div><br></br>
                    <div className='describe'>
                        रूपेश ग्राहक सेवा केंद्र
                        पूरे भारत में उपलब्ध है,
                        यहां तक ​​कि दूरदराज के इलाकों में भी लोगों के लिए इसे आसान बनाना
                        इन सेवाओं तक पहुँचने के लिए.</div>
                </div>

                <div className='other_div'>
                    <h2 className='header_links'>Links</h2>
                    <br></br>
                    <div className='header_linksdiv' >
                        <ul>
                            <Link to="home" spy={true} smooth={true} activeClass="active" offset={-160}> <li style={{ color: 'white' }}>  Home</li></Link>
                            <Link to="about" spy={true} smooth={true} activeClass="active" offset={-160}> <li style={{ color: 'white' }}>  About</li> </Link>
                            <Link to="services" spy={true} smooth={true} activeClass="active" offset={-160}><li style={{ color: 'white' }}> Services</li></Link>
                            <Link to="contact" spy={true} smooth={true} activeClass="active" offset={-160}><li style={{ color: 'white' }}> Contact</li></Link>
                        </ul>
                    </div>
                </div>
                <div className='other_div'>
                    <h2 className='header_links'>Main Services</h2>
                    <br></br>
                    <div className='header_linksdiv' >
                        <ul>
                            <Link to='bank' spy={true} smooth={true} activeClass="active" offset={-160}> <li style={{ color: 'white' }}>बैंकिंग सेवाएं</li></Link>
                            <Link to='recharge' spy={true} smooth={true} activeClass="active" offset={-160}> <li style={{ color: 'white' }}>रिचार्ज सेवाएँ</li> </Link>
                            <Link to='govt' spy={true} smooth={true} activeClass="active" offset={-160}><li style={{ color: 'white' }}>शासकीय सेवाएं</li></Link>
                            <Link to='travel' spy={true} smooth={true} activeClass="active" offset={-160} ><li style={{ color: 'white' }}>यात्रा सेवाएं</li></Link>

                        </ul>
                    </div>
                </div>
                <div className='one_div'>
                    <h2 className='header_links'>Contact Us</h2>
                    <br></br>
                    <div className='header_linksdiv' >
                        <ul>
                            <li><FaLocationArrow />&nbsp;&nbsp;मेन रोड सड्डू (तरपोंगी) रायपुर,(सी.जी.)</li>
                            <li><FaLocationArrow />&nbsp;&nbsp;खाद गोदाम के सामने, सांकरा,रायपुर,(सी.जी.)</li>
                            <li>
                                <a href="tel:${9617787279}" style={{ color: "white" }}><FaPhoneAlt />&nbsp;  9617787279</a>, &nbsp;
                                <a href="tel:${9109799434}" style={{ color: "white" }}><FaPhoneAlt />&nbsp;  9109799434</a>
                            </li>
                            <li>&nbsp;<FaEnvelope />&nbsp;mahendranirmalkar61@gmail.com</li>
                            <li>   
                                  <span >
                                <FaInstagramSquare />&nbsp;&nbsp;
                                <FaFacebook />&nbsp;&nbsp;
                                <FaLinkedin />&nbsp;&nbsp;
                                <IoLogoYoutube />&nbsp;&nbsp;
                            </span></li>
                        </ul>
                    </div>
                </div >
            </div >

            <div className='copyright'>
                <a href='https://yashvitech.com' target='_blank'>
                    <h5>Powered By YashviTech IT Solution</h5>
                </a>
            </div>
            <FloatingWhatsApp avatar={logo} accountName='रूपेश ग्राहक सेवा' notificationSound={true} phoneNumber='916260863579' />

        </>
    )
}

export default Rupeshseva_footer