import React from 'react'
import './Rupeshseva_eightdivs.css'
function Rupeshsev_eightdivs() {
    return (
        <div>
            <div className='rupeshseva_eightdiv_first_div'>
                <h3>
                    हमारे पास बैंकिंग सेवाओं सहित डिजिटल सेवा सेवाओं की एक विस्तृत श्रृंखला है, जिसमें शामिल हैं -
                </h3>
            </div>
            <div className='rupeshseva_eightdiv_Eight_div'>
                <div className='rupeshseva_eightdiv_first_row'>
                    <div className='rupeshseva_eightdiv_div1'>
                        <span style={{ borderBottom: '2px solid gold',color:'gold' }}>एईपीएस :</span>  
                        <p style={{ padding: '2%' }}>आधार सक्षम भुगतान प्रणाली (एईपी) ग्राहक सेवा केंद्र और डिजिटल सेवा केंद्र भौतिक दस्तावेजों या
                            कार्ड की आवश्यकता के बिना सक्षम भुगतान प्रणाली के
                            माध्यम से आधार कार्ड हस्तांतरण भुगतान का उपयोग करके सुरक्षित और समय पर भुगतान को बढ़ावा देते हैं।</p>
                    </div>
                    <div className='rupeshseva_eightdiv_div2'>
                        <span style={{ borderBottom: '2px solid gold',color:'gold' }}> आधार पे  :</span>  <p style={{ padding: '2%' }}> हम उपयोगकर्ताओं को एक ऑनलाइन प्लेटफॉर्म प्रदान करते हैं जो सुरक्षित और
                            आसान डिजिटल भुगतान के लिए आधार कार्ड
                            और बायोमेट्रिक प्रमाणीकरण का उपयोग करके कैशलेस भुगतान की अनुमति देता है।</p>
                    </div>
                </div>
                <div className='rupeshseva_eightdiv_first_row'>
                    <div className='rupeshseva_eightdiv_div1'>
                        <span style={{ borderBottom: '2px solid gold',color:'gold' }}> नकद जमा  :</span> <p style={{ padding: '2%' }}>  हम ग्राहकों को उनके मौजूदा बैंक खातों में नकदी जमा करने की सुविधा प्रदान करते हैं, और धन का सुचारू हस्तांतरण सुनिश्चित करते हैं।</p>



                    </div>
                    <div className='rupeshseva_eightdiv_div2'>
                        <span style={{ borderBottom: '2px solid gold',color:'gold' }}> मनी ट्रांसफर  : </span> <p style={{ padding: '2%' }}>  हम अपने उपयोगकर्ताओं को तुरंत पैसे ट्रांसफर करने या डिजिटल रूप से भुगतान प्राप्त
                            करने की सुविधा प्रदान करते हैं। हम अपने प्रियजनों को परेशानी मुक्त तरीके से पैसे ट्रांसफर करने
                            और लाभार्थियों को भुगतान करने में सक्षम बनाते हैं।</p>
                    </div>
                </div>
                <div className='rupeshseva_eightdiv_first_row'>
                    <div className='rupeshseva_eightdiv_div1'>
                        <span style={{ borderBottom: '2px solid gold',color:'gold' }}> सीएसपी कियोस्क बैंकिंग :</span>   <p style={{ padding: '2%' }}> हम दूरदराज या ग्रामीण क्षेत्रों में उपयोगकर्ताओं को बैंक खाता खोलने,
                            नकद जमा करने और निकासी जैसी कियोस्क बुनियादी बैंकिंग सेवाएं प्रदान करते हैं,
                            और हम कियोस्क बैंकिंग सेवाओं के लिए ग्राहक सेवा केंद्र (सीएसपी) के रूप में कार्य करते हैं,
                            जो दूरदराज के क्षेत्रों में खाता खोलने,
                            नकद जमा करने और निकासी जैसी बुनियादी बैंकिंग सुविधाएं प्रदान करते हैं।</p>



                    </div>
                    <div className='rupeshseva_eightdiv_div2'>
                        <span style={{ borderBottom: '2px solid gold',color:'gold' }}>मिनी और माइक्रो एटीएम :</span>  <p style={{ padding: '2%' }}>ग्राहक सेवा केंद्र पर उपलब्ध कॉम्पैक्ट एटीएम ग्राहकों को बुनियादी बैंकिंग
                            लेनदेन जैसे नकदी निकासी,
                            शेष राशि की जांच और धन हस्तांतरण करने में मदद करते हैं, जहां बैंकिंग सेवाएं सीमित हैं।</p>
                    </div>
                </div>
                <div className='rupeshseva_eightdiv_first_row'>
                    <div className='rupeshseva_eightdiv_div1'>
                        <span style={{ borderBottom: '2px solid gold',color:'gold' }}> ऋण आवेदन सेवाएं :</span>  <p style={{ padding: '2%' }}>हम आपको विभिन्न प्रकार के ऋणों, जैसे व्यक्तिगत ऋण, गृह ऋण,
                            या व्यवसाय ऋण के लिए आवेदन करने में सहायता करते हैं,
                            और हम ऋण आवेदन प्रक्रिया के लिए उचित सहायता और मार्गदर्शन प्रदान करते हैं।</p>



                    </div>
                    <div className='rupeshseva_eightdiv_div2'>
                        <span style={{ borderBottom: '2px solid gold',color:'gold' }}>क्रेडिट कार्ड के लिए आवेदन करना: </span>   <p style={{ padding: '2%' }}>हम क्रेडिट कार्ड सुविधा के लिए आवेदन करने में
                            सहायता करते हैं और उपयोगकर्ताओं को उनके वित्तीय विकल्पों का विस्तार
                            करने और क्रेडिट सुविधाएं प्रदान करके उनकी वित्तीय क्षमता बढ़ाने में मदद करते हैं।</p>
                    </div>
                </div>
            </div>
            <div className='rupeshseva_eightdiv_lastthird_div'>
                <h4>
                    रूपेश ग्राहक सेवा केंद्र एक सरकारी क्रांतिकारी अवधारणा है  जो पूरे भारत में ग्रामीण और शहरी दोनों क्षेत्रों में नागरिकों और सरकार को डिजिटल रूप से जोड़ती है
                </h4>
                <h4>
                    इससे ग्रामीण क्षेत्रों में रोजगार के अधिक अवसर पैदा करने में भी मदद मिलती है।
                </h4>
                <h4>
                    रूपेश ग्राहक सेवा केंद्र केवल बैंकिंग सेवाओं तक ही सीमित नहीं है, बल्कि यह बहुत सस्ती कीमत पर बिल भुगतान, पंजीकरण, कर दाखिल करने और<br></br> यात्रा बुकिंग से संबंधित सेवाएं भी प्रदान करता है।

                </h4>
            </div>



        </div>
    )
}

export default Rupeshsev_eightdivs
