import React from 'react'
import './Rupeshsev_about.css'

function Rupeshsev_about() {
  return (
    <div id='about'>

      <div className='rupeshseva_about_main_about'>
        <h1 className='rupeshseva_about_heading'>ABOUT RUPESH GRAHAK SEVA KENDRA</h1>

        <p className='rupeshseva_about_description'>
          रूपेश ग्राहक सेवा केंद्र एक क्रांतिकारी अवधारणा है जिसका उद्देश्य  विक्रेताओं के नेटवर्क के माध्यम से ग्रामीण और
          शहरी क्षेत्रों में बैंकिंग और आवश्यक सेवाएं पहुंचाना है। यह पहल लोगों को सशक्त बनाने और उन्हें विभिन्न सेवाओं
          तक आसान पहुंच प्रदान करने के लिए शुरू की गई है जो पहले उनके क्षेत्रों में अनुपलब्ध थीं। ग्राहक सेवा केंद्र बैंकिंग,
          बिल भुगतान, सरकार और पंजीकरण सेवाओं सहित सेवाओं की एक विस्तृत श्रृंखला प्रदान करता है।
          केंद्र सभी आवश्यक सेवाओं के लिए वन-स्टॉप-शॉप के रूप में कार्य करता है,
          जिससे ग्रामीण और शहरी दोनों क्षेत्रों में लोगों के लिए जीवन अधिक सुविधाजनक हो जाता है।
           विक्रेताओं के माध्यम से ये सेवाएं प्रदान करके,
          ग्राहक सेवा केंद्र इन क्षेत्रों में रोजगार के नए अवसर पैदा करने और आर्थिक विकास को बढ़ावा देने में भी मदद कर रहा है।
        </p>
        <div className='rupeshseva_about_description_div' >
          <div className='rupeshseva_about_description1'>
            <h3>उद्देश्य</h3><br></br>
            <p className='rupeshseva_about_paragraph1'> रूपेश ग्राहक सेवा केंद्र में, हमारा मिशन ग्रामीण समुदायों के लोगों को सुलभ और
              सस्ती डिजिटल सेवाएं प्रदान करके शहरी और ग्रामीण क्षेत्रों के बीच डिजिटल विभाजन
              को दुर करना   है। हम अपने ग्राहकों को बैंकिंग, रिचार्ज और बिल भुगतान, सरकारी सेवाओं,
               और यात्रा सेवाओं जैसी आवश्यक सेवाओं
              तक  पहुंचने  का सशक्त प्रयास कर रहे है।</p>
          </div>
          <div className='rupeshseva_about_description2'>
            <h3>दृष्टि</h3><br></br>
            <p className='rupeshseva_about_paragraph2'>
              रूपेश ग्राहक सेवा केंद्र में हमारा दृष्टिकोण एक ऐसा भविष्य बनाना है जहां  हम एक ऐसी दुनिया की कल्पना करते हैं जहां हर
              व्यक्ति को डिजिटल सेवाओं
              और विकास के अवसरों तक समान पहुंच प्राप्त हो, और हम इस दृष्टि को वास्तविकता बनाने के लिए प्रतिबद्ध हैं।
            </p>
          </div>
        </div>


      </div>
    </div>
  )
}

export default Rupeshsev_about
