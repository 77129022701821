import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { TextField, Button, Box, Typography } from '@mui/material';
import { Modal, Form, Input, } from 'antd';
export default function BasicTextFields() {
  // State to hold form data
  const form = useRef();
  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    phone: '',
    description: ''
  });

  // Handler for input change
  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
  };


  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_yuy3xcw', 'template_nwntsit', form.current, {
        publicKey: '_sMllx6ildFptUIe9',
      })
      .then(
        () => {
          setIsModalVisible(true);
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
  const [isModalVisible, setIsModalVisible] = useState(false);



  const handleOk = () => {
    setIsModalVisible(false);
    

  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Modal
        title="Thank You!"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <p>Your message has been sent successfully.</p>
        <br />
        <Button type="submit" style={{backgroundColor:' #1B4F72',color:'gold'}}
         variant="contained" fullWidth onClick={handleOk} >
          Close
        </Button>
      </Modal>



      <Box
        component="form"
        ref={form}
        onSubmit={sendEmail}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          maxWidth: 400,
          mx: 'auto',
          p: 2
        }}
      >
        <Typography variant="h6">Contact Us</Typography>
        <TextField
          label="आपका नाम"
          name="name"
          variant="outlined"
          fullWidth
          required
        />
        <TextField
          label="आपका ईमेल"
          name="email"
          type="email"
          variant="outlined"
          fullWidth
          required
        />
        <TextField
          label="आपका फ़ोन नंबर"
          name="phone"
          variant="outlined"
          fullWidth
          required
        />
        <TextField
          label="विवरण"
          name="description"
          variant="outlined"
          multiline
          rows={4}
          fullWidth
          required
        />
        <Button type="submit" style={{backgroundColor:' #1B4F72',color:'gold'}}
        variant="contained" fullWidth >
          Submit
        </Button>
      </Box>
    </>
  );
}
