import React from 'react'
import Rupeshsev_hero from './Rupeshsev_hero'
import Rupeshseva_sevices from '../Services/Rupeshseva_sevices'
import Rupeshsev_about from '../About/Rupeshsev_about'
import Rupeshseva_whytouse from './Rupeshseva_whytouse'
import Rupeshsev_eightdivs from '../Services/Rupeshsev_eightdivs'
import Rupeshseva_Enquiry from '../Contact/Rupeshseva_Enquiry'
import TopNavbar from '../Nav/TopNavbar';
import Rupeshseva_header from '../Header/Rupeshseva_header'
import Slider from '../Landing page/Slider'
import Rupeshseva_yojna from '../YojnaPages/Rupeshseva_yojnaye'
function Landing() {
  return (
    <div>
      {/* <TopNavbar/> */}
      <Slider/>
     {/* <Rupeshsev_hero/> */}
     <Rupeshsev_about/>
     <Rupeshseva_sevices/>
   
     <Rupeshsev_eightdivs/>
     <Rupeshseva_yojna/>
     <Rupeshseva_whytouse/>
     <Rupeshseva_Enquiry/>
 
       </div>
  )
}

export default Landing
