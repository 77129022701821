import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import './Rupeshsev_hero.css'
import { FaPhoneAlt, FaEnvelope, FaHome, FaServicestack, FaQuestionCircle, FaInfoCircle } from 'react-icons/fa';
import instagram from '../photos/instagram.png'
import twitter from '../photos/twitter.png'
import youtube from '../photos/youtube.png'
import facebook from '../photos/facebook (1).png'
import { Button, Carousel } from 'antd';
import { FaFileContract } from 'react-icons/fa6';
import img1 from '../photos/hero-img.png'
import logo from '../photos/Rupesh_CSC_Logo-removebg-preview.png'
// import video from '../Landing page/videos.mp4'


function Rupeshsev_hero() {

  const navigate = useNavigate()

  const Yojnaye = () => {
    navigate('/yojana')

  }
  return (
    <div>
      {/* <div className='rupeshseva_hero_cursole_div5' >
        <div className='div1_rupeshhero_image'>
          <img src={img1} className='rupeshseva_hero_cursole_image' />
        </div>
        <div className='rupeshseva_hero_cursole_paragraph'>
          <p className='paragrapgh_rupesh' >रूपेश<br></br> ग्राहक सेवा केंद्र</p>
          <p style={{ fontSize: '20px' }}>सड्डू और सांकरा</p>
          <button className="rameshseva_button_cursole">
            <span className="rameshseva_button_content_cursole">Contact Here  </span>
          </button>
        </div> */}
        {/* <div >
      <video className='video_herorupesh'  src={video} loop muted autoPlay  ></video>
      </div> */}



    </div>
  )
}

export default Rupeshsev_hero
